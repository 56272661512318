import React, { useEffect, useRef, useState } from 'react';

import './modal.css';
import Modal from './modal';
import { BASE_URL, S3_BASE_URL, axiosApiInstance, failureAlert, successAlert } from '../../helpers/helper';
import { useDispatch, useSelector } from 'react-redux';
import { getOrgTeams } from '../../redux/actions/teamAction';
import LoadingComponent from '../loader/LoadingComponent';
import { ToastContainer } from 'react-toastify';
import dropDownImg from '../../assets/teams-images/icon down.png'
import Confirm from './confirm';
import { uploadFile } from '../../redux/actions/commonActions';
import { BsUpload } from 'react-icons/bs';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { GoStar, GoStarFill } from 'react-icons/go';
import arrowback from "../../assets/images/arrow-back.svg";
import ROLES from '../../helpers/userTypes';
import search from "../../assets/images/search.svg";
import { BiSolidLeftArrow } from "react-icons/bi";



const EditTeamModal = ({ modalid, toggle, setOpenAddTeamModal, editTeamInfo }) => {


    const [teamName, setTeamName] = useState('');
    const [loaded, setLoaded] = useState(true)
    const [openConfirmUploadModal, setOpenConfirmUploadModal] = useState(false);
    const [uploadFlag, setUploadFlag] = useState(false);
    const [file, setFile] = useState(null);
    const [searchText, setSearchText] = useState('')

    const [openAddNewMembersModal, setOpenAddNewMembersModal] = useState(false);
    const [sendNewMembersMail, setSendNewMembersMail] = useState({
        isMail: false,
        closeModal: false,
        filePath: null
    });

    const [numOfTeamsCount, setNumOfTeamsCount] = useState(0)

    const [orgAllUsers, setOrgAllUsers] = useState([]);

    const [selectedTeamInfo, setSelectedTeamInfo] = useState()
    const [selectedTeamUsersData, setSelectedTeamUsersData] = useState([])
    const [teamCaptainData, setTeamCaptainData] = useState({
        userId: null,
        teamId: null
    })
    const [teamLogo, setTeamLogo] = useState(null)

    const addTeamMembersCsv = useRef(null);
    const history = useHistory()


    const getAllOrgsTeam = useSelector((state) => state.getOrgsTeams);
    const { orgTeams } = getAllOrgsTeam;
    const UserInfo = useSelector((state) => state.getUser);
    const { userInfo } = UserInfo;

    const dispatch = useDispatch();


    useEffect(() => {
        if (editTeamInfo) {
            getAllUsers(editTeamInfo.id);
            setSelectedTeamInfo(editTeamInfo);
            setTeamLogo(editTeamInfo.teamLogo);

        }
        if (editTeamInfo && editTeamInfo?.teamCaptainId) {
            setTeamCaptainData({
                userId: editTeamInfo.teamCaptainId,
                teamId: editTeamInfo.id
            })
        }
        else {
            setTeamCaptainData({
                userId: null,
                teamId: null
            })
        }
    }, [editTeamInfo]);

    useEffect(() => {
        if (searchText) {
            getAllUsers(editTeamInfo.id, searchText);
        }
        else {
            getAllUsers(editTeamInfo.id, searchText);

        }
    }, [searchText])


    useEffect(() => {

        if (sendNewMembersMail && sendNewMembersMail.closeModal === true) {
            addNewTeamsMembers(sendNewMembersMail.filePath, sendNewMembersMail.isMail)
        }
    }, [sendNewMembersMail])


    useEffect(() => {
        async function callBack() {
            if (uploadFlag) {
                if (file) {
                    const fileData = new FormData();
                    fileData.append("sheets", file);
                    const { status, data } = await dispatch(uploadFile(fileData, true));
                    if (status === 200) {
                        if (data && data.data && data.data.path) {
                            const { path } = data.data;
                            const checkCsvUsers = await axiosApiInstance.post(BASE_URL + '/api/organization-team/check-teams-members/csv', { filePath: path.replace(BASE_URL + "/", ""), teamId: selectedTeamInfo.id });
                            if (checkCsvUsers.data.message === "valid user data") {
                                setLoaded(true);
                                uploadCsvMembers(path.replace(BASE_URL + "/", ""))

                            }
                            else if (checkCsvUsers.data.message === "there can be only one captain") {
                                failureAlert("File contain more then 1 captain")
                                setLoaded(true);
                            }
                            else if (checkCsvUsers.data.message === "Invalid team Name") {
                                failureAlert("File contain invalid team Name")
                                setLoaded(true);
                            }
                            else if (checkCsvUsers.data.message === "Invalid Fields") {
                                failureAlert("Invalid file format")
                                setLoaded(true);
                            }
                            else if (checkCsvUsers.data.message === "Contain invalid user data") {
                                setLoaded(true);
                                setOpenAddNewMembersModal(true)
                                setSendNewMembersMail((preState) => ({ ...preState, filePath: path.replace(BASE_URL + "/", "") }))
                                setUploadFlag(false);
                            }
                            else {
                                failureAlert("Something went wrong")
                                setLoaded(true);
                            }


                            // setSelectedTeamUsersData([]);
                            // setTeams([]);
                            // setTeamLogo('');
                            // setTeamMemberSearch('');
                            // dispatch(getOrgTeams(userInfo.data.organizationId));

                        }
                    }
                    else if (status?.includes?.("417"))
                        failureAlert("Uploaded file contains some malware!");
                    else if (status?.includes?.("500"))
                        failureAlert("File Format Not supported");
                    setFile(null);
                }
                setUploadFlag(false);
            }
        }
        callBack();
    }, [uploadFlag]);

    const uploadCsvMembers = async (filePath) => {
        setLoaded(false);
        const checkUserActivity = await axiosApiInstance.post(BASE_URL + '/api/organization-team/add-members/csv', { filePath: filePath, teamId: selectedTeamInfo.id });

        if (checkUserActivity.data.message === "Users added to team sucesssfully") {
            successAlert("Users assigned to the team successfully");
            setLoaded(true);
            setTeamCaptainData({
                teamId: null,
                userId: null
            });
            setSelectedTeamUsersData([]);
            setTeamName('');
            // setTeams([]);
            // setTeamLogo('');
            // setTeamMemberSearch('');
            dispatch(getOrgTeams(userInfo.data.organizationId));
            getAllUsers(editTeamInfo.id);
            setOpenAddTeamModal(false);


        }
        else if (checkUserActivity.data.message === "there can be only one captain") {
            failureAlert("File contain more then 1 captain")
            setLoaded(true);
        }
        else if (checkUserActivity.data.message === "Invalid team Name") {
            failureAlert("File contain invalid team Name")
            setLoaded(true);
        }
        else if (checkUserActivity.data.message === "Invalid Fields") {
            failureAlert("Invalid file format")
            setLoaded(true);
        }
        else if (checkUserActivity.data.message === "invaild field data") {
            failureAlert("File contain invaild user data")
            setLoaded(true);
        }
        else {
            failureAlert("Something went wrong")
            setLoaded(true);
        }
    }

    const addNewTeamsMembers = async (filePath, isMailed) => {
        setLoaded(false);
        const checkUserActivity = await axiosApiInstance.post(BASE_URL + '/api/organization-team/add-teams-new-members/csv', { filePath: filePath, isMailed: isMailed, teamId: selectedTeamInfo.id });

        if (checkUserActivity.data.message === "user added sucessfully") {
            setLoaded(true);
            uploadCsvMembers(filePath);
            setSendNewMembersMail({
                isMail: false,
                closeModal: false,
                filePath: null
            })
            setOpenAddNewMembersModal(false);

        }
        else if (checkUserActivity.data.message === "there can be only one captain") {
            failureAlert("File contain more then 1 captain")
            setLoaded(true);
            setSendNewMembersMail({
                isMail: false,
                closeModal: false,
                filePath: null
            })
            setOpenAddNewMembersModal(false);
        }
        else if (checkUserActivity.data.message === "Invalid team Name") {
            failureAlert("File contain invalid team Name")
            setSendNewMembersMail({
                isMail: false,
                closeModal: false,
                filePath: null
            })
            setOpenAddNewMembersModal(false);
            setLoaded(true);
        }
        else if (checkUserActivity.data.message === "Invalid Fields") {
            failureAlert("Invalid file format")
            setSendNewMembersMail({
                isMail: false,
                closeModal: false,
                filePath: null
            })
            setOpenAddNewMembersModal(false);
            setLoaded(true);
        }
        else if (checkUserActivity.data.message === "invaild field data") {
            failureAlert("File contain invaild user data")
            setSendNewMembersMail({
                isMail: false,
                closeModal: false,
                filePath: null
            })
            setOpenAddNewMembersModal(false);
            setLoaded(true);
        }
        else {
            failureAlert("Something went wrong")
            setSendNewMembersMail({
                isMail: false,
                closeModal: false,
                filePath: null
            })
            setOpenAddNewMembersModal(false);
            setLoaded(true);
        }
    }



    const handleCsvFileClick = () => {
        addTeamMembersCsv.current.click();
    };

    const getSelectedChekced = (userId) => {
        const userSelection = selectedTeamUsersData.find(selection => selection.userId === userId);
        return userSelection && userSelection.teamId === null ? false : userSelection && userSelection.teamId !== null ? true : (orgAllUsers.find(user => user.id === userId)?.organizationTeam?.id ? true : false);
    };

    const handleTeamCaptainSelection = (userId) => {
        let isUserSeleceted = getSelectedChekced(userId);
        if (userId && isUserSeleceted === true) {
            setTeamCaptainData({
                userId: userId,
                teamId: selectedTeamInfo.id
            })
        }
    }

    const getAllUsers = async (orgteamId, searchText) => {
        setLoaded(false)
        const usersInfo = await axiosApiInstance.post(BASE_URL + "/api/organization-team/get-allusers", { organizationId: userInfo.data.organizationId, organisationTeamId: orgteamId, search: searchText })
        if (usersInfo && usersInfo.data && usersInfo.data.message === "Succesfully fetch organization users") {
            setOrgAllUsers(usersInfo.data.data);
        }
        setLoaded(true)
    }

    const handleTeamChange = (userId, teamId) => {

        setSelectedTeamUsersData((prevSelectedTeams) => {
            const updatedSelection = prevSelectedTeams.filter(selection => selection.userId !== userId);
            const updatedSelectionUnique = prevSelectedTeams.find(selection => selection.userId === userId);
            const userSelection = orgAllUsers.find(user => user.id === userId)?.organizationTeam?.id;

            if (userSelection && userSelection === teamId && !updatedSelectionUnique) {
                updatedSelection.push({ userId, teamId: null });
                if (teamCaptainData && teamCaptainData.teamId === teamId && teamCaptainData.userId === userId) {
                    setTeamCaptainData({
                        userId: null,
                        teamId: null
                    })
                }

            } else if (updatedSelectionUnique) {
                if (updatedSelectionUnique.teamId === teamId) {
                    updatedSelection.push({ userId, teamId: null });
                    if (teamCaptainData && teamCaptainData.teamId === teamId && teamCaptainData.userId === userId) {
                        setTeamCaptainData({
                            userId: null,
                            teamId: null
                        })
                    }

                } else {
                    updatedSelection.push({ userId, teamId });
                }
            } else {
                updatedSelection.push({ userId, teamId });
            }

            return updatedSelection;
        });


    };

    const handleSaveChanges = async (e) => {
        e.preventDefault();

        if (file && !uploadFlag) {
            setUploadFlag(true)
        }
        else {
            let isTeamUserAssign = orgAllUsers.filter((user) => user.organisationTeamId === selectedTeamInfo.id);
            if (isTeamUserAssign.length < 1 && selectedTeamUsersData && selectedTeamUsersData.length < 1) {
                return failureAlert("Please assign team members")
            }


            if (!teamName) {
                if (selectedTeamUsersData && selectedTeamUsersData.length < 1 && teamCaptainData.userId === selectedTeamInfo.teamCaptainId) {
                    return;
                }

            }
            else {

                if (selectedTeamUsersData && selectedTeamUsersData.length < 1 && teamCaptainData.userId === selectedTeamInfo.teamCaptainId && teamName === selectedTeamInfo.name) {
                    return;
                }

            }

            if (!teamCaptainData.teamId || !teamCaptainData.userId) {
                return failureAlert("Please assign team captain")
            }


            if (userInfo && userInfo.data && userInfo.data.organizationId) {

                const body = {
                    teamcaptainInfo: teamCaptainData,
                    usersTeam: selectedTeamUsersData,
                    teamLogoData: {
                        teamId: null,
                        logo: null
                    },
                    teamName: {
                        name: teamName,
                        teamId: selectedTeamInfo.id,
                    }
                }

                setLoaded(false);

                const response = await axiosApiInstance.post(BASE_URL + "/api/organization-team/update-team", body);
                if (response && response.data && response.data.message === "org user Updated sucessfully") {
                    successAlert("Sucessfully added user to team");
                    setTeamCaptainData({
                        teamId: null,
                        userId: null
                    });
                    setSelectedTeamUsersData([]);
                    setTeamName('');
                    // setTeams([]);
                    // setTeamLogo('');
                    // setTeamMemberSearch('');
                    dispatch(getOrgTeams(userInfo.data.organizationId));
                    getAllUsers(editTeamInfo.id);
                    setOpenAddTeamModal(false);


                }
                else {
                    failureAlert("!Opps something went wrong")
                }
                setLoaded(true);

            }
            else {
                failureAlert("!Opps something went wrong")
            }
        }

    }

    function capitalizeWords(str) {
        if (!str) {
            return '';
        }
        return str.split(' ').map(word =>
            word.charAt(0).toUpperCase() + word.slice(1)
        ).join(' ');
    }

    const handleRenameChange = (e) => {
        let val = e.target.value
        setTeamName(val.toLowerCase());
    }

    const csvFileChanged = (e) => {
        e.persist();
        const inputFile = e.target.files[0];
        e.target.value = [];
        setFile(inputFile);
        successAlert("File uploaded sucessfully")

    };


    const handleSampleDownload = () => {

        const downloadLink = `${S3_BASE_URL}TeamMembers.csv`;


        const anchor = document.createElement('a');
        anchor.href = downloadLink;
        anchor.download = "TeamMembers.csv";
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    };

    const handleChange = (e) => {
        setSearchText(e.target.value)
    }



    return (
        <Modal modalid={modalid} toggle={toggle}>
            <LoadingComponent loaded={loaded} />
            {/* <ToastContainer position="bottom-center" /> */}
            <div className="relative flex items-center justify-center w-[100vw] h-[100vh] lg:w-[950px] lg:h-[640px]">

                <div className="close-icon hidden  lg:!flex " data-dismiss="modal" aria-label="Close" onClick={() => setOpenAddTeamModal(false)} >
                    <div className="close-btn-icon" ></div>
                </div>
                <div className="flex items-center justify-start flex-col rounded-[10px] w-full lg:w-[950px] h-full lg:h-[640px] p-[20px] bg-white pl-[20px] pr-[20px] md:pl-[70px] md:pr-[70px] lg:pl-[70px] lg:pr-[70px]">
                    <div className="flex lg:!hidden items-center justify-start w-full">
                        <div className="flex items-center w-[65px] cursor-pointer mb-2" onClick={() => setOpenAddTeamModal(false)}>
                            <BiSolidLeftArrow className='w-[18px] h-[18px] text-black' />
                            <span className=' font-sans text-[18px] text-black ml-1'>Back</span>
                        </div>
                        {/* <img src={arrowback} alt="back" onClick={() => setOpenAddTeamModal(false)} className='flex h-[25px] w-[25px] hover:cursor-pointer' /> */}
                    </div>
                    <div className="flex items-center w-full">
                        <h4 className='text-[20px] md:text-[24px] lg:text-[24px] text-[#23282E] font-semibold'>Rename <span className='text-[20px] md:text-[24px] lg:text-[24px] font-semibold text-[#E25569]'>{`"${selectedTeamInfo && selectedTeamInfo?.name ? capitalizeWords(selectedTeamInfo?.name) : ''}"`}</span></h4>

                    </div>
                    <div className="w-full h-[40px] mt-[20px]">
                        <input type="text" className='w-full h-[35px] md:h-full lg:h-full placeholder:text-[#696f76] focus:border-[0.5px] focus:border-[#696f76] focus:outline-none border-[0.5px] border-[#696f76] rounded-[6px] pl-4 text-[16px]' placeholder='Enter New Team Name'
                            onChange={handleRenameChange}
                            value={teamName}
                        />
                    </div>

                    <div className="flex items-center justify-between w-full p-[15px] bg-[#F6F9FE] flex-row mt-[20px] rounded-[6px]">
                        <div className="flex flex-col w-auto">
                            <h3 className=' text-[12px] md:text-[14px] lg:text-[14px] text-[#23282E] font-sans font-semibold'>Upload File <span className='text-blue-500 text-[10px] md:text-[14px] lg:text-[14px] font-semibold underline ml-1 cursor-pointer' onClick={handleSampleDownload}> Download Sample </span></h3>
                            <h3 className=' text-[8px] md:text-[14px] lg:text-[14px] text-[#696f76] font-sans mt-1'>Upload CSV file to add users</h3>

                        </div>
                        <div className="flex flex-col w-[105px] md:w-[140px] lg:w-[140px] h-[40px] md:h-[45px] lg:h-[45px] items-center justify-center  ">
                            <div className="flex items-center justify-center h-[100%] btn-tooltip">
                                <button type="submit"
                                    className={` btn btn-secondry w-[100px] h-[28px] md:w-[120px] md:h-[33px] lg:w-[120px] lg:h-[33px] relative`}
                                    style={{
                                        padding: '0px',
                                    }}
                                    onClick={handleCsvFileClick}
                                >
                                    <input
                                        type="file"
                                        accept=".csv"
                                        onChange={csvFileChanged}
                                        className="hidden"
                                        ref={addTeamMembersCsv}
                                    />
                                    <BsUpload />

                                    <span className=" !text-[10px] sm:!text-[11px] md:!text-[14px] lg:text-[14px] "> Upload Excel</span>
                                </button>
                                {
                                    file && file?.name ? (
                                        <span className=" absolute flex items-center justify-center w-[105px] md:w-[140px] lg:w-[140px] bottom-[-11px] text-[10px] text-ellipsis whitespace-nowrap">{file.name}</span>

                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center w-full mt-[15px] md:mt-[20px] lg:mt-[20px]">
                        <h4 className=' text-[15px] md:text-[18px] lg:text-[18px] text-[#23282E] font-semibold'>Add Participants</h4>

                    </div>

                    <div className="flex items-center justify-between w-full p-[15px] bg-[#F6F9FE] flex-row mt-[15px] md:mt-[20px] lg:mt-[20px] rounded-[6px]">
                        <div className="flex flex-col w-auto">
                            <h3 className='text-[12px] md:text-[14px] lg:text-[14px] text-[#23282E] font-sans font-semibold'>Select Participants</h3>
                            <h3 className='text-[10px] md:text-[14px] lg:text-[14px] text-[#E25569] font-sans mt-1'>"Choosing The Star Will Assign The Teammate As Captain."</h3>

                        </div>
                        <div className="flex items-center justify-end h-full w-auto">
                            <div className="flex w-[260px] h-[40px] bg-white rounded-[6px] py-[8px] px-[12px]">
                                <img src={search} alt="search" className='!w-[15px]' />
                                <input
                                    type="text"
                                    name="search"
                                    placeholder="Search a participant"
                                    onChange={handleChange}
                                    value={searchText}
                                    className=' focus:outline-none focus:border-none outline-none border-none ml-[8px]'
                                />
                            </div>
                        </div>

                    </div>


                    <div className="flex flex-col items-center justify-start overflow-y-scroll no-scrollbar w-full h-[203px] rounded-[6px] pt-[17px] z-50">

                        {
                            orgAllUsers && orgAllUsers.length > 0 && orgAllUsers.map((user, index) => {
                                return (
                                    <div className="flex items-center w-full h-[40px] min-h-[40px] md:h-[50px] md:min-h-[50px] lg:h-[50px] lg:min-h-[50px] flex-row border-[0.5px] border-[#696f76] rounded-[6px] mt-1 pl-[15px] pr-[15px]">
                                        <div className="flex items-center w-2/6 overflow-hidden">
                                            <span className='text-[10px] md:text-[14px] lg:text-[14px] font-sans font-semibold overflow-hidden text-ellipsis'>{`${user.firstName ? capitalizeWords(user.firstName) : ''} ${user?.lastName ? capitalizeWords(user.lastName) : ''}`}</span>
                                        </div>
                                        <div className=" hidden md:flex lg:flex items-center w-2/6">
                                            <span className='text-[10px] md:text-[14px] lg:text-[14px] font-sans font-semibold overflow-hidden text-ellipsis'>{user?.email}</span>

                                        </div>
                                        <div className="flex items-center w-2/6 md:w-1/6 lg:w-1/6">
                                            <span className='text-[10px] md:text-[14px] lg:text-[14px] font-sans font-semibold'>{user?.role === ROLES.ORG_SUPER_ADMIN ? "Owner" : user?.role === ROLES.ORG_ADMIN ? "Admin" : user?.role === ROLES.EMPLOYEE ? "EMPLOYEE" : "User"}</span>

                                        </div>
                                        <div className="flex flex-row justify-around items-center w-2/6 md:w-1/6 lg:w-1/6">

                                            <div className="flex items-center justify-center h-full mr-2">
                                                {/* <GoStarFill style={{ width: '22px', height: '21px', cursor: 'pointer' }} fill='#5ECA87'
                                    // onClick={(e) => handleTeamCaptainSelection(user.id)} 
                                    /> */}
                                                {
                                                    teamCaptainData && teamCaptainData.userId === user.id ? (
                                                        <GoStarFill style={{ width: '22px', height: '21px', cursor: 'pointer' }} fill='#5ECA87' onClick={(e) => handleTeamCaptainSelection(user.id)} />
                                                    )
                                                        : (
                                                            <GoStar style={{ width: '22px', height: '21px', color: '#23282E52', opacity: '0.5', cursor: 'pointer' }} onClick={(e) => handleTeamCaptainSelection(user.id)} />
                                                        )
                                                }

                                            </div>

                                            <div className="flex h-[32px] w-[32px] items-center justify-center">

                                                <label class="checkbox-label">
                                                    <input type="checkbox" class="checkbox-tow bg-[#f9cdd3] hover:bg-[#f9cdd3]"
                                                        style={{ background: '#f9cdd3' }}
                                                        checked={getSelectedChekced(user.id)}
                                                        onClick={(e) => { handleTeamChange(user.id, selectedTeamInfo.id) }}
                                                    />
                                                    <div class="svg-icon text-[#fefefe]">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            height="16px"
                                                            viewBox="0 0 448 512"
                                                        >
                                                            <path
                                                                d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                                                                fill='#E25569'
                                                            ></path>
                                                        </svg>
                                                    </div>
                                                    <span class="check-box-container bg-[#E2556914]"></span>
                                                </label>
                                            </div>

                                        </div>

                                    </div>
                                )
                            })
                        }

                    </div>



                    <div className="flex flex-row w-full justify-center mt-[10px] md:mt-[15px] lg:mt-[15px] items-center">
                        <button type="submit" className="btn btn-secondry h-[32px] w-[100px] mr-7" onClick={() => setOpenAddTeamModal(false)}>Cancel</button>

                        <button type="submit" className="btn btn-primary h-[32px] w-[100px]" onClick={(e) => handleSaveChanges(e)}>Save</button>
                    </div>
                </div>
            </div>

            {
                openAddNewMembersModal &&
                <Confirm modalid={'add-new-members'} message="We found email addresses that don't belong to our organization. Do you want to send invitation emails to these external addresses?" setUploadFlag={setSendNewMembersMail} setOpenConfirmUploadModal={setOpenAddNewMembersModal} toggle={openAddNewMembersModal} />
            }

        </Modal>
    );
};
export default EditTeamModal;
