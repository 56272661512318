import React, { useState, useEffect, useRef } from "react";

import OnBoard from "../../components/onboard/onboard";
import Header from "../../components/header/header";
import PhoneInput from 'react-phone-input-2'
import { DeviceUUID } from 'device-uuid';

//Redux
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { axiosApiInstance, decryptData, encryptData } from "../../helpers/helper";
import { BASE_URL } from '../../helpers/helper';

// //Social media login
// import GoogleLogin from "react-google-login";
// //import FacebookLogin from "react-facebook-login";
// import { LinkedIn } from "react-linkedin-login-oauth2";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import Axios from "axios";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from 'uuid'; 


const GuestUser = (props) => {
  const [uuid, setUuid] = useState('');

  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState({});
  const [orgSignInBanner, setOrgSignInBanner] = useState('');
  const [countryDailCode, setCountryDailCode] = useState()
  const [touched, setTouched] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
    businessUnit: false,
  });
  const [guestUserFields, setGuestUserFields] = useState([
    {
      name: 'First Name',
      key: 'firstName',
      isActive: true,
      isRequire: true
    },
    {
      name: 'Last Name',
      key: 'lastName',
      isActive: true,
      isRequire: true
    },
  ])
  const [defaultinputValue, setDefaultInputeValue] = useState({ email: "Enter Your Email", firstName: "Enter Your First Name", lastName: "Enter Your Last Name", orgName: "Enter Your Organization Name", phoneNo: "+91 99999-99999", password: "Enter Your Password", verifyPassword: " Verify Your Password" })


  const [validPhone, setValidPhone] = useState(true);
  const [errorMessage, setErrorMessage] = useState({
    firstName: "",
    lastName: "",
    email: "",
    businessUnit: "",
    phoneNumber: ""
  });

  let inviteId = new URLSearchParams(props.location.search).get('inviteId');
  const sessionid = new URLSearchParams(props.location.search).get('sessionId');



  const getOrCreateUUID = () => {
    let storedUUID = Cookies.get('user_uuid');  // Check if UUID is stored in cookies
    if (!storedUUID) {
        storedUUID = uuidv4();                  // Generate new UUID if not found
        Cookies.set('user_uuid', storedUUID, { expires: 365 }); // Store the new UUID for 1 year
    }
    return storedUUID;
};


useEffect(() => {
  const existingUUID = getOrCreateUUID();
  setUuid(existingUUID);  // Set the UUID in the state
  // console.log("existingUUID "+existingUUID);
  
}, []);

  // var du = new DeviceUUID().parse();

  // var dua = [
  //     du.language,
  //     du.platform,
  //     du.os,
  //     du.cpuCores,
  //     du.isAuthoritative,
  //     du.silkAccelerated,
  //     du.isKindleFire,
  //     du.isDesktop,
  //     du.isMobile,
  //     du.isTablet,
  //     du.isWindows,
  //     du.isLinux,
  //     du.isLinux64,
  //     du.isMac,
  //     du.isiPad,
  //     du.isiPhone,
  //     du.isiPod,
  //     du.isSmartTV,
  //     du.pixelDepth,
  //     du.isTouchScreen
  // ];
  // var uuid = du.hashMD5(dua.join(':'));


//   const generateDeviceUUID = () => {
//     // Use DeviceUUID to gather device info
//     var du = new DeviceUUID().parse();
//     var dua = [
//         du.language,
//         du.platform,
//         du.os,
//         du.cpuCores,
//         du.isAuthoritative,
//         du.silkAccelerated,
//         du.isKindleFire,
//         du.isDesktop,
//         du.isMobile,
//         du.isTablet,
//         du.isWindows,
//         du.isLinux,
//         du.isLinux64,
//         du.isMac,
//         du.isiPad,
//         du.isiPhone,
//         du.isiPod,
//         du.isSmartTV,
//         du.pixelDepth,
//         du.isTouchScreen,
//         navigator.userAgent,  // Include user agent to differentiate similar devices
//     ];
    
//     // Combine the device data to generate a unique hash
//     const combinedData = dua.join(':');
    
//     // Create a consistent hash for the device
//     const uuid = du.hashMD5(combinedData);
    
//     return uuid;
// };

// var uuid = generateDeviceUUID()


  useEffect(() => {
    (async () => {
      if (sessionid) {
        try {
          const result = await axiosApiInstance.get(
            BASE_URL + `/api/session/get-guest-user-fields/${sessionid}`,
          );
          if (result?.status === 200 && result?.data?.data?.guestUserFields?.length) {
            setGuestUserFields(result?.data?.data?.guestUserFields)
          }
        } catch (err) {
          console.log("err - ", err);
        }
      }
    })();

  }, [sessionid]);

  useEffect(()=>{
    // console.log("guestUserFields  value data:  "+JSON.stringify(guestUserFields));
    
if(guestUserFields && guestUserFields.length > 0){
  guestUserFields.map((data, indx)=>{
    if(data.name === "Device ID" && data.key === 'employeeId'){
      setInputValue((prevState) => ({
        ...prevState,
        [data.key]:uuid,
      }));
    }
  })
}
  },[guestUserFields])

  useEffect(() => {
    window.scrollTo(0, 0);
    const gameId = new URLSearchParams(props.location.search).get('gameId');
    if (sessionid && gameId) {
      sessionStorage.setItem("sessionId", encryptData(sessionid));
      sessionStorage.setItem("gameId", encryptData(gameId));
      fetchOrgSigInBanner();
    }
    if (inviteId)
      sessionStorage.setItem("inviteId", encryptData(inviteId));
    else
      sessionStorage.setItem("inviteId", encryptData(""));
  }, [inviteId]);

  const fromOtpPage = decryptData(localStorage.getItem("fromOtpPage") || encryptData(false));

  function validateEmail(email) {
    const regex = /[A-Z]/;
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase()) && !regex.test(email);
  }

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (touched[name]) {
      setTouched(prevState => ({ ...prevState, [name]: false }));
    }
    setInputValue((prevState) => ({
      ...prevState,
      [name]: name === "email" ? value.toLowerCase() : value,
    }));

    console.log("inpute value  change:   "+JSON.stringify(inputValue));
    
  };

  const login = async (e) => {
    e.preventDefault();

    setLoading(true);
    let tempErrorMessage = validateInput(inputValue);

    setErrorMessage({ ...tempErrorMessage });
    const requiredFields = guestUserFields.reduce((acc, item) => {
      // if(item.key === "phoneNumber"){
      //   if (item.isRequire) {
      //     acc[item.key] = true
      //   }
      //   else if(!item.isRequire && !validPhone){
      //     acc[item.key] = true

      //   }

      // }
      // else{
      //   if (item.isRequire) {
      //     acc[item.key] = true
      //   }
       
      // }
      // return acc

      if (item.isRequire || (item.key === "phoneNumber" && !validPhone)) {
        acc[item.key] = true;
      }
      return acc;

    }, {})
    setTouched(requiredFields);

    const sessionId = new URLSearchParams(props.location.search).get('sessionId');
    const gameId = new URLSearchParams(props.location.search).get('gameId');
    // let isAnyInvalidInput = errorMessage.email || errorMessage.firstName || errorMessage.lastName || errorMessage.businessUnit || errorMessage?.phoneNumber;
    let isAnyInvalidInput = Object.keys(requiredFields).some(value => errorMessage[value]);
    if (isAnyInvalidInput) {
      setLoading(false);
      return;
    }

    const nonCustomFields = [
      "email", "firstName", "lastName", "zip", "employeeId", "province", "city", "address", "country", "position", "phoneNumber", "businessUnit",]

    const updatedInputValues = Object.keys(inputValue).reduce((acc, item) => {
      if (nonCustomFields.includes(item)) {
        if(item === "phoneNumber" && (inputValue[item].length === countryDailCode || inputValue[item].length === 2) ){
          console.log("phoneNumber updaeValues:   "+inputValue[item].length+"   --  "+countryDailCode);

          acc[item] = ''
        }
        else{
          acc[item] = inputValue[item]

        }
      } else {
        acc["customFields"] = acc["customFields"] ? { ...acc["customFields"], [item]: inputValue[item] } : { [item]: inputValue[item] }
      }
      return acc

    }, {})
// console.log("updatedInputValues: "+JSON.stringify(updatedInputValues));

    let response = await Axios.post(BASE_URL + `/api/user/save/guest-user`, {
      updatedInputValues,
      region: "ASIA",
      sessionId,
      gameId
    });
    if (response && response.status === 200) {
      if (response.data && response.data.data && response.data.data.redirectUrl) {
        window.location.replace(response.data.data.redirectUrl + '&role=GUEST_USER');
      }
    } else {
      setLoading(false);
    }
  };

  // function validateEmail(email) {
  //   if (!email) {
  //     return false;
  //   } else {
  //     const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //     return re.test(String(email).toLowerCase());
  //   }
  // }

  function validatePhone(phone) {
    if (!phone) {
      return false;
    } else {
      const re = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
      return re.test(`+${phone}`);
    }
  }

  const validateInput = (input) => {
    const errors = { ...errorMessage };
    //errors.email = (input.email === "") ? "Please provide a Email" : (!validateEmail(input.email) ? "Please provide a valid Email" : "");
    guestUserFields.forEach((value, inx) => {
      if (value?.isRequire) {
        errors[value.key] = (!input[value.key] || input[value.key] === "") ? `Please provide a ${value?.name}` : ""
      }
      if (value.key === 'email' && errors.email === "" && !validateEmail(input[value.key])) {
        errors[value.key] = `Invalid email`;
      }

      if (value.key === 'phoneNumber' && input[value.key] !== "" && !validPhone) {
        errors[value.key] = "Invalid phone no"
      }
      else if (value.key === 'phoneNumber' && input[value.key] === "" && value?.isRequire && validPhone) {
        errors[value.key] = `Please provide a ${value?.name}`
      }
      else if (value.key === 'phoneNumber' && input[value.key] !== "" && value?.isRequire && (input[value.key]?.length === countryDailCode || input[value.key]?.length === 2)) {
        errors[value.key] = `Please provide a ${value?.name}`
      }
      else if (value.key === 'phoneNumber' && input[value.key] === "" && !value?.isRequire && validPhone) {
        errors[value.key] = `Invalid phone no`
      }

    })
    // errors.firstName = (input.firstName === "") ? "Please provide a First Name" : "";
    // errors.lastName = (input.lastName === "") ? "Please provide a Last Name" : "";
    //errors.phoneNumber = (input.phoneNumber === "") ? "Please provide a Phone Number" : (!validatePhone(input.phoneNumber) ? "Please provide a valid Phone Number" : "");
    return errors;
  };

  useEffect(() => {
    let tempErrorMessage = validateInput(inputValue);
    setErrorMessage(tempErrorMessage);
  }, [inputValue, guestUserFields]);

  const getOrgIdFromInviteId = () => {
    if (inviteId) {
      try {
        const { organizationId } = decryptData(inviteId);
        return organizationId;
      } catch (err) {
        console.log(err)
      }
    }
    return null;
  }
  const invitedOrgId = getOrgIdFromInviteId();

  const fetchOrgSigInBanner = async () => {
    setOrgSignInBanner('')
    const sessionid = new URLSearchParams(props.location.search).get('sessionId');
    const getSigninBannerImg = await axiosApiInstance.get(BASE_URL + "/api/organization/signin-banner/" + sessionid);
    if (getSigninBannerImg && getSigninBannerImg.data && getSigninBannerImg.data.data !== null) {
      setOrgSignInBanner(getSigninBannerImg.data.data);
    }

  }
  const [countryCodeLength , setCountryCodeLength] = useState(0)
const [defaultCountry, setDefaultCountry] = useState('in')
  return (
    <div>
      <Header {...props} disableHomepageButton={fromOtpPage} onboard inviteId={invitedOrgId} />
      <ToastContainer style={{ marginTop: '4rem' }} position="bottom-center" />
      <OnBoard sideBannerImage={orgSignInBanner}>
        <div className="auth-flow login">
          <h5 className="guest-login-heading">Insert below details and participate now</h5>
          <form onSubmit={async (e) => await login(e)}>
            <div className="auth-f1111guestUserFieldsorm" id="guest-user-form">
              {guestUserFields.map((value, inx) => {
                return value?.isRequire ? (

                  <div className="form-group">
                    {
                      value.name === "Phone" ? (
                        <>
                          <label htmlFor={value.key}>{`${value.name}${value?.isRequire ? `*` : ``}`}</label>


                          <PhoneInput
                            type="numbers"
                            className={"form-control-phone"}
                            name="phoneNo"
                            enableLongNumbers
                            country="in"
                            onChange={(val, country, e, formattedValue) => {
                              if (country && ((country.format && formattedValue && formattedValue.length === country.format.length) || (val.length <= country.dialCode.length))) {
                                setCountryDailCode(country.dialCode.length)

                                setCountryCodeLength(country.format.length)
                                setValidPhone(true);
                                setTouched(prevState => ({ ...prevState, [value.key]: false }));

                                setInputValue((prevState) => ({
                                  ...prevState,
                                  [value.key]: val,
                                }));
                              }
                              else {
                                setCountryDailCode(country.dialCode.length)

                                setValidPhone(false);
                                setTouched(prevState => ({ ...prevState, [value.key]: true }));
          
                                setInputValue((prevState) => ({
                                  ...prevState,
                                  [value.key]: val,
                                }));


                              }
                            }}
                            value={inputValue[value.key] ? inputValue[value.key] : defaultinputValue.phoneNo}
                            onFocus={() => {
                              setDefaultInputeValue((prev) => ({ ...prev, phoneNo: "" }))
                              if (!inputValue[value.key]) {
                                setInputValue((prevState) => ({
                                  ...prevState,
                                  [value.key]: "+91",
                                }));
                                setValidPhone(false);
                                setTouched(prevState => ({ ...prevState, [value.key]: true }));

                              }
                            }}
                            id="phoneNo"
                            containerStyle={{
                              borderBottom: "1px solid #bfc1d7",
                            }}
                          />
                          {touched[value.key] &&
                            <div className="error-message">{errorMessage[value?.key]}</div>
                          }

                        </>
                      ) : (
                        <>
                          <label htmlFor={value.key}
                          style={{
                            display: value.name === "Device ID" ? 'none' : 'flex' 
                          }}
                          >{`${value.name}${value?.isRequire ? `*` : ``}`}</label>

                          <input
                            className="form-control"
                            id={value.key}
                            name={value.key}
                            placeholder={`Enter your my ${value.name}`}
                            onChange={handleChange}
                            onBlur={() => setTouched({ [value.key]: true })}
                            value={inputValue[value.key]}
                            style={{
                              display: value.name === "Device ID" ? 'none' : 'flex' 
                            }}
                          />
                          {touched[value.key] && <div className="error-message">{errorMessage[value.key]}</div>}
                        </>
                      )
                    }
                  </div>

                ) : (
                  <div className="form-group">
                    {
                      value.name === "Phone" ? (<>
                        <label htmlFor={value.key}>{`${value.name}${value?.isRequire ? `*` : ``}`}</label>


                       
                        <PhoneInput
                            type="numbers"
                            className={"form-control-phone"}
                            name="phoneNo"
                            enableLongNumbers
                            country='in'
                            onChange={(val, country, e, formattedValue) => {
                              if (country && ((country.format && formattedValue && formattedValue.length === country.format.length) || (val.length <= country.dialCode.length))) {
                                setCountryCodeLength(country.dialCode.length)
                                setCountryDailCode(country.dialCode.length)
                                console.log("COUNTRY PH: "+JSON.stringify(country));

                                setValidPhone(true);
                                setTouched(prevState => ({ ...prevState, [value.key]: false }));

                
                                setInputValue((prevState) => ({
                                  ...prevState,
                                  [value.key]: val,
                                }));
                              }
                              else {
                                setCountryDailCode(country.dialCode.length)
                                setCountryCodeLength(country.dialCode.length )
                                setValidPhone(false);
                                setTouched(prevState => ({ ...prevState, [value.key]: true }));
                            
                                setInputValue((prevState) => ({
                                  ...prevState,
                                  [value.key]: val,
                                }));


                              }
                            }}
                            value={inputValue[value.key] ? inputValue[value.key] : defaultinputValue.phoneNo}
                            onFocus={() => {
                              setDefaultInputeValue((prev) => ({ ...prev, phoneNo: "" }))
                              if (!inputValue[value.key]) {
                                setInputValue((prevState) => ({
                                  ...prevState,
                                  [value.key]: "91",
                                }));
                                setValidPhone(true);
                                setTouched(prevState => ({ ...prevState, [value.key]: true }));

                              }
                            }}
                            id="phoneNo"
                            containerStyle={{
                              borderBottom: "1px solid #bfc1d7",
                            }}
                          />
                          {touched[value.key] &&
                            <div className="error-message">{errorMessage[value?.key]}</div>
                          }
                      </>) : (
                        <>
                          <label htmlFor={value.key}>{value.name}</label>

                          <input
                            className="form-control"
                            id={value.key}
                            name={value.key}
                            placeholder={`Enter your ${value.name}`}
                            onChange={handleChange}
                            value={inputValue[value.key]}
                          />
                        </>
                      )
                    }

                  </div>
                )
              })}
              {/* <div className="form-group">
                <input
                  className="form-control"
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                  onChange={handleChange}
                  onBlur={() => setTouched({ firstName: true })}
                  value={inputValue.firstName}
                />
                {touched.firstName && <div className="error-message">{errorMessage.firstName}</div>}
              </div>
              <div className="form-group">
                <input
                  className="form-control"
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                  onChange={handleChange}
                  onBlur={() => setTouched({ lastName: true })}
                  value={inputValue.lastName}
                />
                {touched.lastName && <div className="error-message">{errorMessage.lastName}</div>}
              </div> */}
              {/* <div className="form-group">
                <input
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="Email"
                  onChange={handleChange}
                  onBlur={() => setTouched({ email: true })}
                  value={inputValue.email}
                />
                {touched.email && <div className="error-message">{errorMessage.email}</div>}
              </div>
              <div className="form-group">
                <PhoneInput
                  type="numbers"
                  className="form-control"
                  name="phoneNumber"
                  enableLongNumbers
                  placeholder="+91-98765-43210"
                  country="in"
                  onChange={(val, country, e, formattedValue) => {
                    if (country && ((country.format && formattedValue && formattedValue.length === country.format.length) || (val.length <= country.dialCode.length)))
                      setValidPhone(true);
                    else
                      setValidPhone(false);
                    setInputValue((prevState) => ({
                      ...prevState,
                      phoneNumber: val
                    }))
                  }}
                  value={inputValue.phoneNumber}
                  id="phoneNo"
                />
                {touched?.phoneNumber && <div className="error-message">{errorMessage.phoneNumber}</div>}
              </div>
              <div className="form-group">
                <input
                  className="form-control"
                  id="businessUnit"
                  name="businessUnit"
                  placeholder="Business Unit"
                  onChange={handleChange}
                  onBlur={() => setTouched({ businessUnit: true })}
                  value={inputValue.businessUnit}
                />
                {touched.businessUnit && <div className="error-message">{errorMessage.businessUnit}</div>}
              </div> */}
              <button type="submit" className="btn btn-primary">
                Continue To Play
                {loading && (
                  <div role="status">
                    <svg aria-hidden="true" className="w-6 h-6 ml-2 text-gray-200 animate-spin dark:text-gray-600 fill-red-300" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </button>
            </div>
          </form>
        </div>
      </OnBoard>
    </div>
  );
};

export default GuestUser;
