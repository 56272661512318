import React, { useState } from 'react';
import cardVector from '../../assets/icons/cardVector.png'; // Adjust path as needed
import './gamesection.css'; // Import the CSS file
import { S3_BASE_URL } from "../../helpers/helper";
import { Link } from 'react-router-dom';

const GameSection = ({ gameData, onScrollToSection, onBtnClick }) => {


  const [isHovered, setIsHovered] = useState(false); // No card is hovered by default
  const [cardKey, setCardKey] = useState(5); // Default cardKey to 5

  const hover = (state, key) => {
    setIsHovered(state);
    setCardKey(state ? key : 5); // Set cardKey to hovered key or default to 5
  };

  return (
    <>
      {gameData[0]?.games && gameData[0]?.games.length === 5 && (
        <div className='game-section-non-logged-in-user'>
          <div className='c-line-container'>
            {gameData[0]?.games && [1, 2, 3, 4, 5].map((key) => (
              <div
                key={key}
                className={`card1 card-${key % 2 === 0 ? 'left' : 'right'}-${Math.ceil(key / 2)} ${isHovered && key == cardKey ? 'shadowCard' : ''}`}
                onMouseEnter={() => hover(true, key)}
                onMouseLeave={() => hover(false, key)}
              >
                <img src={S3_BASE_URL + gameData[0]?.games[key - 1]?.coverMedia[1]} />
                <div className='game-card-item-overlay' style={{
                  opacity: (isHovered || key === 5) && cardKey === key ? 1 : 0,
                  top: isHovered
                    ? ('47%'
                    )
                    : '50%' // Always 50px when not hovered
                }}>
                  <div className='outer-container-play'>
                    <div className='left-container'>
                      <img src={cardVector} alt='Logo' className='logo-play' />
                      <div className='title-container-hover'>
                        <p className='title-play'>{gameData[0]?.games[key - 1].title}</p>
                        {/* <p className='light'>{gameData[0]?.games[key - 1].maxPlayers} Players</p> */}
                      </div>
                    </div>
                    <div className='right-container'>
                      <Link to={`/game-detail/${gameData[0]?.games[key - 1]?.id}`} className="button-play">Play</Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className='strip'></div>
          </div>
        </div>
      )}
    </>
  );
};

export default GameSection;